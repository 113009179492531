import React from "react"
import Layout from "../components/layout"
import garden13 from "../images/garden13-min.jpg"

import { AiOutlineInstagram } from "@react-icons/all-files/ai/AiOutlineInstagram"
import { AiOutlineFacebook } from "@react-icons/all-files/ai/AiOutlineFacebook"

import "./contact.css"

const ContactPage = () => {
  return (
    <Layout imageUrl={garden13} pageTitle="Contact us">
      <div className="section intro-block">
        <div className="headline">Viewings are by appointment only </div>
      </div>

      <div className="contact-details">
        <div>
          <h3> Address </h3>
          <p>
            Old Court Wedding Company <br />
            Strangford <br />
            Co. Down <br />
            Northern Ireland <br />
            BT30 7NB
          </p>
        </div>
        <div>
          <h3> Phone/email us </h3>
          <p>
            <a href="tel:00447706992945">(+44)7706992945 </a>
            <br />
            <br />
            <a href="mailto:info@oldcourtweddingcompany.co.uk">
              info@oldcourtweddingcompany.co.uk
            </a>
          </p>
        </div>
        <div>
          <h3> Connect with us </h3>
          <a
            href="hhttps://www.facebook.com/oldcourtweddingcompany"
            target="_blank"
            rel="noreferrer"
            className="facebook"
          >
            <AiOutlineFacebook size="2em" />
          </a>
          <a
            href="https://www.instagram.com/explore/locations/1000072381/old-court-wedding-company/?hl=en"
            target="_blank"
            rel="noreferrer"
          >
            <AiOutlineInstagram size="2em" />
          </a>
        </div>
      </div>
      <div className="map">
        <iframe
          width="100%"
          height="100%"
          title="maps iframe"
          src="https://maps.google.com/maps?q=old%20court%20chapel&t=&z=13&ie=UTF8&iwloc=&output=embed"
        ></iframe>
      </div>
    </Layout>
  )
}
export default ContactPage
